
import './App.css';
import {useState,useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import Popup from 'reactjs-popup';


function App() {
  //creating IP state
  const [latitude, setlatitude] = useState('');
  const [longitude, setlongitude] = useState('');
  const [city , setcity] = useState('');
  const [cityCode, setCityCode] = useState('');
  const [citylist, setCitylist] = useState('');
  

  async function  handleSubmit(event) {
    setCitylist("");
    event.preventDefault();

    const url = "https://api.nightexchange.com/web/productInfo?npa=" + event.target.areacode.value;
    const nearbycities = await axios.get(url);

    await setCitylist(nearbycities.data);
  }
  
  useEffect( () => {
    //passing getData method to the lifecycle method
    const getData = async () => {
      const res = await axios.get('https://geolocation-db.com/json/');
      await setlatitude(res.data.latitude);
      await setlongitude(res.data.longitude);
    }

    if(latitude && longitude){
      const url = "https://api.nightexchange.com/web/productInfo?lat="+latitude+"&long="+longitude+"&brand=1";

      const getcitydata = async () => {
        const citydata = await axios.get(url);
        await setcity(citydata.data.cityname);
        await setCityCode(citydata.data.phonenum);
      }

      getcitydata();
    }

    getData();
  }, [latitude,longitude])

  return (
    <div className="App">
      <h2 id="locationtext">Talk to Local Singles in - {city ? city : ""} call - {cityCode ? <a href={`tel:+${cityCode}`}>{cityCode}</a> : ""}</h2>
      
      <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="" id="locationsearch">Not in this area ? Enter your area code to get your number : </label>
        <input id="areacode" type="number" required min={100} max={999}/>
      </div>
      <Popup
       trigger={<button type="submit">Get My Number</button>}
       modal
       nested
     >
       {close => (
      <div className="modal">
        <button className="close-popup" onClick={close}>
          &times;
        </button>
        <div className="header"> Cities Near you.. </div>
        <div className="content">
      <h4>{citylist ? <p> {citylist[0].cityname} - <a href={`tel:+${citylist[0].phonenum}`}>{citylist[0].phonenum}</a> </p> : ""}</h4>
      <h4>{citylist ? <p> {citylist[1].cityname} - <a href={`tel:+${citylist[1].phonenum}`}>{citylist[1].phonenum}</a> </p> : ""}</h4>
      <h4>{citylist ? <p> {citylist[2].cityname} - <a href={`tel:+${citylist[2].phonenum}`}>{citylist[2].phonenum}</a> </p> : ""}</h4>
      <h4>{citylist ? <p> {citylist[3].cityname} - <a href={`tel:+${citylist[3].phonenum}`}>{citylist[3].phonenum}</a> </p> : ""}</h4>
      <h4>{citylist ? <p> {citylist[4].cityname} - <a href={`tel:+${citylist[4].phonenum}`}>{citylist[4].phonenum}</a> </p> : ""}</h4>
      <h4>{citylist ? <p> {citylist[5].cityname} - <a href={`tel:+${citylist[5].phonenum}`}>{citylist[5].phonenum}</a> </p> : ""}</h4>
      <h4>{citylist ? <p> {citylist[6].cityname} - <a href={`tel:+${citylist[6].phonenum}`}>{citylist[6].phonenum}</a> </p> : ""}</h4>
      <h4>{citylist ? <p> {citylist[7].cityname} - <a href={`tel:+${citylist[7].phonenum}`}>{citylist[7].phonenum}</a> </p> : ""}</h4>
      <h4>{citylist ? <p> {citylist[8].cityname} - <a href={`tel:+${citylist[8].phonenum}`}>{citylist[8].phonenum}</a> </p> : ""}</h4>
      <h4>{citylist ? <p> {citylist[9].cityname} - <a href={`tel:+${citylist[9].phonenum}`}>{citylist[9].phonenum}</a> </p> : ""}</h4>
      
      <h4>{citylist && citylist[0].cityname ? "" : <p>Cant find your city? call - <a href='tel:+1800333333'>1800-333-333</a></p>}</h4>
          <br />
        </div>
        <div className="actions">
          <button
            className="button"
            onClick={() => {
              setCitylist("");
              close();
            }}
          >
            Done
          </button>
        </div>
      </div>
    )}
     </Popup>
    </form>

     

    </div>

    


  );
}

export default App;